import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="bg-gray-100 my-4 py-20" id='portfolio' style={{paddingTop:"100px", paddingBottom:"140px"}}>
                <h2 className="my-2 text-center text-5xl text-red-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-red-900 mb-8'></div>
                </div>

                <div className="px-3" data-aos="fade-down" data-aos-delay="600" style={{paddingTop:"50px"}}>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <br></br>
                                <h4 className="font-semibold my-4 text-lg md:text-3xl text-center mb-4 h-12">Beginner Driver Education Program</h4>
                                <br></br>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}20 Hour Online Classes.
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}10 Hour Digital Homework.
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}10 Hour in Vehicle on the Road.
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}MTO Certification Fee included in the package.
                                    
                                </p>
                                <br></br>
                                <h5 className="font-semibold my-4 text-lg md:text-4xl text-center mb-4 h-12"style={{paddingTop:"5px", paddingBottom:"5px"}}>700$ onwards</h5>
                                <h6 className="text-center"> (Tax Included)</h6> 
                                
                                <div className="flex justify-center my-4">
                                <a className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl" href='https://forms.gle/iS9RRD37MthCNBux6'>
                                    {/* <Link to='' href='https://forms.gle/iS9RRD37MthCNBux6' className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"> */}
                                        Sign Up
                                        
                                        
                                        <svg className="w-4 h-4 ml-1" href='https://forms.gle/iS9RRD37MthCNBux6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    {/* </Link> */}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <br></br>
                                <h4 className="font-semibold my-4 text-lg md:text-3xl text-center mb-4 h-12">Individual "G2" Lesson</h4>
                                <br></br>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}1 Driving Lesson as per G2 Driving Guidelines.
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}1 hour of Ontario Specific G2 Test Preparation.
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}Driving Tips and Techniques.
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}Driving School Car available for Drive Test for additional charges. **Please contact to enquire.
                                </p>
                                <h5 className="font-semibold my-4 text-lg md:text-4xl text-center mb-4 h-12"style={{paddingTop:"5px", paddingBottom:"5px"}}>55$ onwards</h5>
                                <h6 className="text-center"> (Tax Included)</h6> 
                                <div className="flex justify-center my-4">
                                <a className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl" href='https://forms.gle/iS9RRD37MthCNBux6'>
                                    {/* <Link to='' href='https://forms.gle/iS9RRD37MthCNBux6' className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"> */}
                                        Sign Up
                                        
                                        
                                        <svg className="w-4 h-4 ml-1" href='https://forms.gle/iS9RRD37MthCNBux6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    {/* </Link> */}
                                    </a>
                                </div>
                                
                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-red-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <br></br>
                            <h4 className="font-semibold my-4 text-lg md:text-3xl text-center mb-4 h-12">Individual "G" Lesson Program</h4>
                            <br></br>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}1 Driving Lesson as per G Driving Guidelines.
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}1 hour of Ontario Specific G Test Preparation.
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}Driving Tips and Techniques
                                    
                                </p>
                                <p className='my-3 text-xl text-gray-600 '>
                                {`\u2714 `}Driving School Car available for Drive Test for additional charges. **Please contact to enquire.
                                    
                                </p>
                                <h5 className="font-semibold my-4 text-lg md:text-4xl text-center mb-4 h-12"style={{paddingTop:"5px", paddingBottom:"5px"}}>$60</h5>
                                <h6 className="text-center"> (Tax Included)</h6> 

                                <div className="flex justify-center my-4">
                                <a className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl" href='https://forms.gle/iS9RRD37MthCNBux6'>
                                    {/* <Link to='' href='https://forms.gle/iS9RRD37MthCNBux6' className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"> */}
                                        Sign Up
                                        
                                        
                                        <svg className="w-4 h-4 ml-1" href='https://forms.gle/iS9RRD37MthCNBux6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    {/* </Link> */}
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Event Management System</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Our event management system helps to manage different types of events, whether weddings, burials or any kind of event. With an easily customizable menu, you get to define your user experience and make your event fully customizable and memorable.
                                    With use cases already recorded for quite a number of events, we can assure you of a stress-less event mangement platform.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>                     */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
        
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-red-900" smooth to="/#home">
                Home
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-red-900" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-red-900" smooth to="/#about">
                About
            </HashLink>
            
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-red-900" to="/#portfolio">
                Packages
            </HashLink>
            {/* <HashLink  className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/contact#contact">
                Contact Us
            </HashLink> */}
            <a className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center  px-3 py-3 my-2 text-lg shadow-xl rounded-xl" href='https://forms.gle/iS9RRD37MthCNBux6'>                   
                Contact Us
                
            </a>
            <a className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center  px-3 py-3 my-2 text-lg shadow-xl rounded-xl" href="tel:+19059293713"> Call Us</a>
            

        </>
    )
}

export default NavLinks;

import React from 'react';
import img from '../images/redcartoon.png';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
        <div className="m-auto max-w-10xl p-2 md:p-12"  id='about'  style={{paddingTop:"100px"}}>
        <h3 className="text-5xl  text-red-900 justify-center lg:text-center font-bold uppercase">about me</h3>
<div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up" style={{paddingTop:"20px", paddingBottom:"50px"}}>
<div className="flex-col  text-center lg:text-left   lg:w-1/2 " >
        

        <div  style={{paddingTop:"100px"}}>
            <p className='my-3 text-2xl text-gray-600 '>I am an MTO certified instructor and I provide Beginner Driver Education Courses. I have nearly 37,000 hours of on-the-road instruction and over 5,000 hours of in-class teaching experience. 
            </p>
        </div>
        <div  style={{paddingTop:"10px"}}>
            <p className='my-3 text-2xl text-gray-600 '>
        
            I offer instruction in English, Urdu, Hindi, and basic Arabic. Whether you're aiming for your G2 or G license, I can help. Take advantage of our BDE course for early road test bookings and insurance discounts. Contact us today to get started!</p>
        </div>

    </div>
    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
        <img alt="card img" className="rounded-t float-right" src={img} />
    </div>
    
</div>
</div>
                
        </>
    )
}

export default Intro;
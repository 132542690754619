import React from "react";
import img from '../images/ADOBELOGO.svg';

function AdobeLogo() {
    return (
        <div className="m-auto max-w-10xl p-2 md:p-12"  id='home'  style={{paddingTop:"100px"}}>

        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up" style={{paddingTop:"50px", paddingBottom:"100px"}}>
        <div className="flex-col  text-center lg:text-left   lg:w-1/2 " >
                
                <h5 className="my-2 text-center text-6xl text-red-900  font-bold" style={{paddingTop:"160px" }}>Sadia Driving Instruction</h5>
                {/* h2 className="my-2 text-center text-4xl text-red-900 uppercase font-bold">Why Choose Us ?</h2> */}
                <div>
                    <p className='my-3 text-xl text-gray-600 '>I am an MTO certified instructor and I provide Beginner Driver Education Courses. I am a Leading Expert in driver education with 15 years of teaching experience, and I provide skills required to be a safe and confident driver for life. We offer a wide range of driving lesson Packages to suit your need.</p>
                </div>
                
                {/* <div>
                    <p className='my-3 text-xl text-gray-600 font-semibold'>We take responsibility for building custom software solutions that caters for automation of your business processes and improve efficiency.</p>
                </div> */}
                {/* <Link to="/contact" className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                    Contact us
                    <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link> */}
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                <img alt="card img" className="rounded-t float-right" src={img} />
            </div>
            
        </div>
    </div>
    );
}

export default AdobeLogo;
import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import NavBar from '../components/Navbar/NavBar';
import OpeningImage from '../components/OpeningIMage';
import AdobeLogo from '../components/AdobeLogo';


const Home = () => {
    return (
        <>
        <div>
        <NavBar/>
        <AdobeLogo/>
        {/* <OpeningImage/> */}
        {/* <Hero /> */}
        <Services />
            <Intro />
            
            
            
            <Portfolio />
            <Clients />
            {/* <Cta/> */}
            <Footer />
        </div>
           
        </>

    )
}

export default Home;


import React from "react";

function SadiaLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55%"
      height="25%"
      version="1.1"
      viewBox="0 0 2446.28 1052.4"
      xmlSpace="preserve"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 1052.4)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path
            fill="#eb1d27"
            d="M3127.16 2703.38s753.44 2338.26 2831.89 649.51c0 0 545.6 300.85 1584.83-18.45 0 0-1636.79 174.34-1714.73-631.06 0 0-753.44 2312.28-2701.99 0"
          ></path>
          <path
            fill="#eb1d27"
            d="M14896.4 2339.65s233.8-103.92 0 363.73l727.5-186.79s285.8 99.01 623.5 0c0 0 155.9 186.79 0 628.46 0 0-883.3-244.75-987.3 189.39 0 0 344.3 96.4 734-117.94 0 0-545.6 876.84-4696 1110.67 0 0-1656.28 1091.19-1812.17 1110.67l-311.77-175.36s1441.94-740.45 1695.24-1052.22c0 0-4072.47-185.21-4286.81-326.43l-155.88-63.28 3916.59-350.74 2708.5-253.31s370.2 233.82 253.3-77.95c0 0-409.2-344.24-331.3-435.17 0 0 1429 1370.48 1922.6-363.73"
          ></path>
          <path
            fill="#eb1d27"
            d="M7381.5 4288.2s-759.94-116.91-1890.1 194.85c0 0-2182.38 171.4-2805.91-216.33 0 0-350.74-60.44-565.08 0 0 0-38.97-631.38 584.56-382.89 0 0 2260.32 852.54 2922.83 384.88 0 0 1636.78-253.31 1753.7 19.49"
          ></path>
          <path
            fill="#eb1d27"
            d="M6504.65 5398.87l-350.74 155.89s-1617.3-487.14-1812.15-935.31c0 0 526.71 425.97 2162.89 779.42"
          ></path>
          <path
            fill="#26346e"
            d="M10986.3 5963.95V4736.37l-331.2 175.37v935.3l331.2 116.91"
          ></path>
          <path
            fill="#26346e"
            d="M11921.6 6509.55L8044 7893.02 4010.5 6529.03 8044 5087.11l2396.7 876.84-1927.4 453.52c-64.6-141.02-206.62-239.18-371.87-239.18-225.99 0-409.19 183.2-409.19 409.2s183.2 409.2 409.19 409.2c211.95 0 386.25-161.15 407.1-367.61l2437.77-431.3 935.3 311.77"
          ></path>
          <path
            fill="#26346e"
            d="M755.039 2152.32c-38.387 63.98-143.973 124.76-265.543 124.76-156.766 0-231.949-65.58-231.949-148.77 0-97.58 115.172-124.77 249.543-140.77 233.558-28.79 451.109-89.57 451.109-356.73 0-249.54-220.758-356.72-470.301-356.72-228.753 0-404.718 70.39-487.898 275.15l175.957 91.18c49.59-123.18 179.164-177.57 315.141-177.57 132.769 0 257.547 46.39 257.547 167.96 0 105.58-110.375 148.77-259.149 164.78-228.754 27.19-439.914 87.97-439.914 339.12 0 230.36 227.156 324.74 433.512 326.34 174.367 0 355.129-49.59 439.914-222.36l-167.969-86.37"
          ></path>
          <path
            fill="#26346e"
            d="M1310.06 1698.01c0-150.37 103.98-239.96 233.56-239.96 307.14 0 307.14 478.3 0 478.3-129.58 0-233.56-87.97-233.56-238.34zm473.51 393.51h187.16v-788.64h-183.96l-9.6 115.18c-44.79-92.78-167.97-137.56-255.95-139.16-233.55-1.62-406.31 142.36-406.31 419.11 0 271.94 180.76 414.31 411.11 412.71 105.58 0 206.36-49.59 251.15-127.98l6.4 108.78"
          ></path>
          <path
            fill="#26346e"
            d="M2293.81 1698.01c0-137.58 99.18-231.96 223.94-231.96 123.18 0 228.76 89.58 228.76 231.96 0 137.56-105.58 230.34-228.76 230.34-124.76 0-223.94-87.97-223.94-230.34zm660.66 723.05V1302.88H2772.1l-12.79 108.78c-60.79-94.38-158.37-126.37-254.35-126.37-231.95 0-406.32 153.57-406.32 412.72 0 271.94 171.17 412.71 401.52 412.71 83.18 0 212.76-44.79 259.15-126.38v436.72h195.16"
          ></path>
          <path
            fill="#26346e"
            d="M3123.98 2094.72v-791.84h195.16v791.84zm212.75 219.16c0-151.97-230.35-151.97-230.35 0s230.35 151.97 230.35 0"
          ></path>
          <path
            fill="#26346e"
            d="M3647.04 1698.01c0-150.37 103.98-239.96 233.56-239.96 307.14 0 307.14 478.3 0 478.3-129.58 0-233.56-87.97-233.56-238.34zm473.51 393.51h187.16v-788.64h-183.96l-9.6 115.18c-44.79-92.78-167.97-137.56-255.95-139.16-233.55-1.62-406.31 142.36-406.31 419.11 0 271.94 180.76 414.31 411.11 412.71 105.58 0 206.36-49.59 251.15-127.98l6.4 108.78"
          ></path>
          <path
            fill="#26346e"
            d="M5005.09 1506.04h230.35c254.35 0 355.13 185.57 350.32 367.92-4.8 174.38-107.18 348.74-350.32 348.74h-230.35zm790.23 366.33c4.8-283.14-167.96-569.49-559.88-569.49h-439.92v1119.78h439.92c383.92 0 555.08-273.54 559.88-550.29"
          ></path>
          <path
            fill="#26346e"
            d="M6100.79 2091.52l14.39-91.17c60.79 97.58 142.38 111.97 222.36 111.97 81.58 0 159.96-32 203.16-75.19l-87.99-169.56c-39.99 33.6-76.78 51.19-140.77 51.19-102.38 0-196.76-54.39-196.76-199.96v-415.92h-195.15v788.64h180.76"
          ></path>
          <path
            fill="#26346e"
            d="M6614.24 2094.72v-791.84h195.15v791.84zm212.75 219.16c0-151.97-230.35-151.97-230.35 0s230.35 151.97 230.35 0"
          ></path>
          <path
            fill="#26346e"
            d="M7802.76 2093.12l-335.93-793.43h-211.16l-337.53 793.43h212.76l108.77-260.75 121.58-332.72 119.98 329.52L7590 2093.12h212.76"
          ></path>
          <path
            fill="#26346e"
            d="M7897.08 2094.72v-791.84h195.16v791.84zm212.76 219.16c0-151.97-230.35-151.97-230.35 0s230.35 151.97 230.35 0"
          ></path>
          <path
            fill="#26346e"
            d="M8848.86 1302.88v412.72c0 119.97-65.58 211.15-190.35 211.15-119.98 0-201.56-100.78-201.56-220.75v-403.12h-193.57v790.24h174.37l12.8-107.18c79.98 78.39 159.97 118.38 259.15 118.38 185.56 0 334.33-139.17 334.33-387.12v-414.32h-195.17"
          ></path>
          <path
            fill="#26346e"
            d="M9365.5 1717.2c0-137.58 92.79-219.15 206.36-219.15 111.98 0 204.76 83.17 204.76 219.15 0 135.98-92.78 222.35-204.76 222.35-113.57 0-206.36-84.78-206.36-222.35zm465.51 454.31l143.97-108.78-87.99-111.97c60.8-68.8 83.19-147.17 83.19-233.56 0-97.58-36.79-235.16-166.36-294.34 131.17-65.59 163.16-159.97 163.16-260.75 0-217.551-166.36-351.93-395.12-351.93-228.75 0-399.91 139.179-399.91 351.93h193.55c0-102.38 94.39-169.559 206.36-169.559 111.98 0 199.96 60.779 199.96 169.559s-102.38 158.37-199.96 158.37c-246.35 0-399.91 150.37-399.91 396.72 0 246.35 179.16 399.92 399.91 399.92 62.39 0 126.38-8 180.77-44.79l78.38 99.18"
          ></path>
          <path
            fill="#26346e"
            d="M10434 1302.88v1119.78h209.6V1302.88H10434"
          ></path>
          <path
            fill="#26346e"
            d="M11408.2 1302.88v412.72c0 119.97-65.6 211.15-190.4 211.15-120 0-201.5-100.78-201.5-220.75v-403.12h-193.6v790.24h174.4l12.8-107.18c79.9 78.39 159.9 118.38 259.1 118.38 185.6 0 334.3-139.17 334.3-387.12v-414.32h-195.1"
          ></path>
          <path
            fill="#26346e"
            d="M12286.3 1881.96c-55.9 52.79-119.9 70.4-195.1 70.4-92.8 0-144-28.8-144-78.4 0-51.18 46.4-79.97 147.2-86.38 148.8-9.6 337.5-43.18 337.5-252.74 0-139.17-113.6-259.16-339.1-259.16-124.8 0-249.6 20.81-364.7 140.78l95.9 139.17c56-62.38 184-108.77 272-110.37 73.6-1.6 142.4 36.78 142.4 94.38 0 54.39-44.8 76.78-156.8 83.18-148.8 11.19-326.3 65.59-326.3 244.75 0 182.37 188.7 246.35 332.7 246.35 123.2 0 216-24 307.1-103.98l-108.8-127.98"
          ></path>
          <path
            fill="#26346e"
            d="M12831.8 2313.88v-223.96h217.6v-167.96h-219.2v-340.74c0-75.18 41.6-111.97 102.4-111.97 30.4 0 65.6 9.59 94.3 24l54.4-166.38c-56-22.39-102.3-31.98-161.5-33.58-171.2-6.4-283.2 91.18-283.2 287.93v340.74h-147.1v167.96h147.1v203.16l195.2 20.8"
          ></path>
          <path
            fill="#26346e"
            d="M13375.6 2091.52l14.4-91.17c60.8 97.58 142.4 111.97 222.4 111.97 81.6 0 160-32 203.1-75.19l-87.9-169.56c-40 33.6-76.8 51.19-140.8 51.19-102.4 0-196.8-54.39-196.8-199.96v-415.92h-195.1v788.64h180.7"
          ></path>
          <path
            fill="#26346e"
            d="M14105 2091.52V1678.8c0-119.97 65.6-211.15 190.4-211.15 120 0 201.5 100.78 201.5 220.76v403.11h193.6v-790.23h-174.4l-12.8 107.17c-81.5-79.97-156.7-118.37-267.1-118.37-188.8 0-326.3 142.38-326.3 387.11v414.32h195.1"
          ></path>
          <path
            fill="#26346e"
            d="M15539.9 1403.67c-91.2-89.59-187.2-124.77-304-124.77-228.7 0-419.1 137.56-419.1 417.51 0 279.93 190.4 417.51 419.1 417.51 112 0 198.4-32 284.8-116.77l-123.2-129.58c-46.4 41.59-104 62.38-158.4 62.38-131.1 0-227.1-95.98-227.1-233.54 0-150.37 102.4-230.36 223.9-230.36 62.4 0 124.8 17.6 172.8 65.58l131.2-127.96"
          ></path>
          <path
            fill="#26346e"
            d="M15901.4 2313.88v-223.96h217.5v-167.96h-219.1v-340.74c0-75.18 41.5-111.97 102.3-111.97 30.4 0 65.6 9.59 94.4 24l54.4-166.38c-56-22.39-102.4-31.98-161.6-33.58-171.1-6.4-283.1 91.18-283.1 287.93v340.74H15559v167.96h147.2v203.16l195.2 20.8"
          ></path>
          <path
            fill="#26346e"
            d="M16264.4 2094.72v-791.84h195.2v791.84zm212.8 219.16c0-151.97-230.4-151.97-230.4 0s230.4 151.97 230.4 0"
          ></path>
          <path
            fill="#26346e"
            d="M16811.5 1696.41c0-119.99 72-231.96 214.3-231.96 142.4 0 214.4 111.97 214.4 231.96 0 118.37-83.2 233.54-214.4 233.54-140.7 0-214.3-115.17-214.3-233.54zm625.5 0c0-227.16-155.2-411.12-411.2-411.12-255.9 0-409.5 183.96-409.5 411.12 0 225.55 156.8 411.11 407.9 411.11 251.2 0 412.8-185.56 412.8-411.11"
          ></path>
          <path
            fill="#26346e"
            d="M18151.9 1302.88v412.72c0 119.97-65.6 211.15-190.3 211.15-120 0-201.6-100.78-201.6-220.75v-403.12h-193.5v790.24h174.3l12.8-107.18c80 78.39 160 118.38 259.2 118.38 185.5 0 334.3-139.17 334.3-387.12v-414.32h-195.2"
          ></path>
          <path
            fill="#eb1d27"
            d="M2817.8 860.5h36.41l259.73-776.75L3377.3 860.5h70.39l-299.77-852h-70.4l-134.71 398.082L2836 742.77l-106.8-333.758L2593.27 8.5h-70.39l-297.35 852h69.18l262.15-776.75L2817.8 860.5"
          ></path>
          <path
            fill="#eb1d27"
            d="M3963.59 334.98c-1.21 148.071-98.31 230.59-242.73 230.59-123.8 0-230.6-83.742-243.95-230.59zm-547.36-24.281c0 179.633 135.93 309.492 304.63 309.492s319.19-103.171 296.13-337.402h-540.08C3489.05 142 3597.06 60.691 3720.86 60.691c78.89 0 171.12 31.547 216.03 91.02l42.48-33.981C3921.12 41.27 3816.73 2.441 3720.86 2.441c-168.7 0-304.63 117.719-304.63 308.258"
          ></path>
          <path
            fill="#eb1d27"
            d="M4530.44 310.699c0-166.269 110.44-251.219 245.16-251.219 140.78 0 245.16 103.161 245.16 252.43 0 149.289-106.8 251.231-245.16 251.231-134.72 0-245.16-83.731-245.16-252.442zM5080.23 860.5V10.93h-58.26V143.21c-49.76-91.012-148.06-142-250.01-142-167.49 0-299.78 110.461-299.78 309.488 0 197.832 133.5 309.492 300.99 309.492 103.16 0 200.25-43.703 248.8-140.781V860.5h58.26"
          ></path>
          <path
            fill="#eb1d27"
            d="M5774.49 309.488c0 155.352-110.44 256.082-246.37 256.082s-246.38-100.73-246.38-256.082c0-155.347 110.45-248.797 246.38-248.797s246.37 93.45 246.37 248.797zm-551.01 0c0 190.551 135.93 311.91 304.64 311.91 168.69 0 304.62-121.359 304.62-311.91 0-190.539-135.93-307.047-304.62-307.047-168.71 0-304.64 116.508-304.64 307.047"
          ></path>
          <path
            fill="#eb1d27"
            d="M6028.19 610.48v-106.8c46.12 82.531 129.86 116.511 213.61 117.718 160.2 0 270.64-98.308 270.64-264.578V10.93h-59.47v344.679c0 135.93-83.74 208.75-213.6 207.532-123.8-1.211-209.97-94.653-209.97-218.45V10.93h-59.47v599.55h58.26"
          ></path>
          <path
            fill="#eb1d27"
            d="M6650.84 633.531l63.12 228.18h69.18l-78.9-228.18h-53.4"
          ></path>
          <path
            fill="#eb1d27"
            d="M7047.73 791.32V610.48h203.9v-49.761h-203.9V195.398c0-81.308 16.99-138.347 110.45-138.347 29.13 0 61.89 9.71 92.23 24.27l20.64-48.551c-37.62-18.2-75.25-30.329-112.87-30.329-127.44 0-168.7 75.239-168.7 192.957v365.321h-127.44v49.761h127.44v174.758l58.25 6.082"
          ></path>
          <path
            fill="#eb1d27"
            d="M7784.48 10.93V553.44h-122.59v52.18h122.59v53.41c0 118.93 47.33 205.11 179.62 205.11 44.9 0 84.96-15.782 118.94-41.27l-29.13-43.68c-32.77 19.411-55.83 30.329-92.24 30.329-75.24 0-118.94-46.118-118.94-150.489v-53.41h197.83v-52.18h-197.83V10.93h-58.25"
          ></path>
          <path
            fill="#eb1d27"
            d="M8653.51 309.488c0 155.352-110.44 256.082-246.37 256.082-135.94 0-246.38-100.73-246.38-256.082 0-155.347 110.44-248.797 246.38-248.797 135.93 0 246.37 93.45 246.37 248.797zm-551.01 0c0 190.551 135.93 311.91 304.64 311.91 168.69 0 304.62-121.359 304.62-311.91 0-190.539-135.93-307.047-304.62-307.047-168.71 0-304.64 116.508-304.64 307.047"
          ></path>
          <path
            fill="#eb1d27"
            d="M8908.43 860.5V10.93h-59.48V860.5h59.48"
          ></path>
          <path
            fill="#eb1d27"
            d="M9134.17 860.5V10.93h-59.47V860.5h59.47"
          ></path>
          <path
            fill="#eb1d27"
            d="M9823.53 309.488c0 155.352-110.44 256.082-246.38 256.082-135.92 0-246.37-100.73-246.37-256.082 0-155.347 110.45-248.797 246.37-248.797 135.94 0 246.38 93.45 246.38 248.797zm-551 0c0 190.551 135.92 311.91 304.62 311.91 168.71 0 304.64-121.359 304.64-311.91 0-190.539-135.93-307.047-304.64-307.047-168.7 0-304.62 116.508-304.62 307.047"
          ></path>
          <path
            fill="#eb1d27"
            d="M10612.5 10.93h-65.6l-97.1 262.148-72.8 233.024-75.2-234.231-97.1-260.941h-65.6l-213.57 599.55h65.53l180.84-530.37 183.3 530.37h42.4l182.1-530.37 179.6 530.37h65.6l-212.4-599.55"
          ></path>
          <path
            fill="#eb1d27"
            d="M10994.8 47.332c0-63.113-95.8-63.113-95.8 0 0 63.109 95.8 63.109 95.8 0"
          ></path>
          <path
            fill="#eb1d27"
            d="M12022.8 860.5h36.5L12319 83.75l263.3 776.75h70.4L12353 8.5h-70.4l-134.8 398.082L12041 742.77l-106.8-333.758L11798.3 8.5h-70.4l-297.3 852h69.1l262.2-776.75 260.9 776.75"
          ></path>
          <path
            fill="#eb1d27"
            d="M13168.6 334.98c-1.2 148.071-98.3 230.59-242.7 230.59-123.8 0-230.6-83.742-244-230.59zm-547.3-24.281c0 179.633 135.9 309.492 304.6 309.492 168.7 0 319.2-103.171 296.1-337.402h-540.1c12.2-140.789 120.2-222.098 244-222.098 78.9 0 171.1 31.547 216 91.02l42.5-33.981c-58.2-76.46-162.6-115.289-258.5-115.289-168.7 0-304.6 117.719-304.6 308.258"
          ></path>
          <path fill="#eb1d27" d="M13769.5 860.5V10.93h-59.5V860.5h59.5"></path>
          <path
            fill="#eb1d27"
            d="M14449.1 334.98c-1.2 148.071-98.3 230.59-242.7 230.59-123.8 0-230.6-83.742-244-230.59zm-547.4-24.281c0 179.633 136 309.492 304.7 309.492s319.2-103.171 296.1-337.402h-540.1c12.2-140.789 120.2-222.098 244-222.098 78.9 0 171.1 31.547 216 91.02l42.5-33.981c-58.3-76.46-162.6-115.289-258.5-115.289-168.7 0-304.7 117.719-304.7 308.258"
          ></path>
          <path
            fill="#eb1d27"
            d="M14683.4 310.699c0-150.5 109.2-251.219 245.2-251.219 333.7 0 333.7 501.239 0 501.239-136 0-245.2-99.52-245.2-250.02zm549.8 299.781V10.93h-59.5v127.429c-58.2-93.449-148-137.148-245.1-137.148-167.5 0-304.7 126.23-304.7 309.488 0 183.27 137.2 307.063 304.7 307.063 97.1 0 195.4-44.91 245.1-138.352v131.07h59.5"
          ></path>
          <path
            fill="#eb1d27"
            d="M15420.1 310.699c0-166.269 110.5-251.219 245.2-251.219 140.8 0 245.2 103.161 245.2 252.43 0 149.289-106.8 251.231-245.2 251.231-134.7 0-245.2-83.731-245.2-252.442zm549.8 549.801V10.93h-58.2V143.21c-49.8-91.012-148.1-142-250-142-167.5 0-299.8 110.461-299.8 309.488 0 197.832 133.5 309.492 301 309.492 103.1 0 200.2-43.703 248.8-140.781V860.5h58.2"
          ></path>
          <path
            fill="#eb1d27"
            d="M16213.9 47.332c0-63.113-95.9-63.113-95.9 0 0 63.109 95.9 63.109 95.9 0"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SadiaLogo;
import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30" style={{background:"black"}}>

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                    
                    {/* 1st block */}
                    {/* <div className="col-span-12 lg:col-span-4">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d371917.9489963639!2d-80.55687283395825!3d43.259616285391566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x218fa33f48415e03%3A0x32fb4f1de650f510!2sSadia%20driving%20instruction!5e0!3m2!1sen!2sca!4v1707278299625!5m2!1sen!2sca" ></iframe>
                    
                    </div> */}
                    <div className="col-span-12 lg:col-span-4">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d371917.9489963639!2d-80.55687283395825!3d43.259616285391566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x218fa33f48415e03%3A0x32fb4f1de650f510!2sSadia%20driving%20instruction!5e0!3m2!1sen!2sca!4v1707278299625!5m2!1sen!2sca" 
        width="100%" 
        height="300" 
        frameBorder="0" 
        tabIndex="0">
    </iframe>
</div>

                    {/* 2nd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <HashLink to="#" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/#portfolio" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                        </li>
                        <li className="mb-2">
                            <a  className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out" href='https://forms.gle/iS9RRD37MthCNBux6'>Contact</a>
                        </li>        
                        <li className="mb-2">
                            <a  className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out" href="tel:+19059293713">Call Us</a>
                        </li>                     
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <HashLink to="/#portfolio" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">Beginner Driver Education Program</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/#portfolio" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">Individual G License Program</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/#portfolio" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">Individual G2 License Program</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/#portfolio" className="text-white hover:text-white-900 hover:tracking-wider transition duration-250 ease-in-out">General Guidance</HashLink>
                        </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold  text-900">
                        <div className="text-xl text-white mb-6">
                            CONTACT US 
                        </div>

                        <div className="text-md text-white font-medium mb-6">
                            Phone: <a href="tel:+19059293713">+1(905)9293713</a>
                            <br></br>
                            email: sadia_anis@yahoo.com 
                            <br></br>
                            Hamilton, Ontario

                        </div>

                    </div>          

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    <HashLink to="#" className=" hover:text-gray-900">
                       Sadia Driving Instruction
                    </HashLink>. Powered by: damanprxxt@gmail.com
                    </div>
                </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
